import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { spiraliImg, mediumImg } from "../styles/spirali.module.css";
import {
  subtitle,
  title3,
  parag,
  paragBold,
  faqSpacer,
  imgContainer,
  imgCaption,
  listItemExt,
  listItem,
  imgContainer2,
  warning,
  link,
} from "../styles/global.module.css";
import Video from "../components/Video";
import { Link } from "gatsby";

// markup
const SpiraliPage = () => {
  return (
    <Layout pageTitle="Spirali">
      <h2 className={subtitle}>
        Spirali is a long-form generative art algorithm
      </h2>
      <StaticImage
        alt="Spirali example image"
        src="../images/spirali.png"
        className={spiraliImg}
      />
      <h3 className={title3}>Important information</h3>

      <p className={parag}>
        Spirali was minted on fxhash in Sept. 21st, 2022 and it can accessed{" "}
        <a
          href="https://www.fxhash.xyz/generative/slug/spirali"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          here.
        </a>{" "}
        <span className={warning}>
          Please, be aware that the use of Chrome browser is strongly
          recommended for this project!
        </span>{" "}
        For more information, please see the{" "}
        <Link to="#faq" className={link}>
          FAQ
        </Link>
        .
      </p>
      <h3 className={title3}>Inspiration</h3>

      <p className={parag}>
        Spirali was inspired by Tyler Hobbs' work with flow fields. However,
        instead of selecting random starting points for each shape to be drawn,
        which is more usual in flow fields works, I wanted to constrain the
        starting points to certain positions so that the shape being drawn could
        expand freely without the risk of colliding with other shapes, that's
        when I got to the spiral flow field.
      </p>

      <h3 className={title3}>Why Spirali?</h3>

      <p className={parag}>
        The name of this collection is not only a direct translation of spirals
        in Italian, but it is also related to Italian cuisine and my own
        personal experience in Italy. The relationship with Italian cuisine is
        due to the immense number of types of pasta that exist, including one
        called spirali. When I was a child I only knew three or four types of
        pasta, but when I became a bit older I was amazed to learn that there
        were many more. Actually, there are hundreds. Just like from the same
        type of basic dough, you can make hundreds of types of pasta, my idea
        was, from just one type of spiral flow field, to make hundreds of
        images. A strategy of forcing creativity by imposing a major constraint.
      </p>

      <p className={parag}>
        The name of this collection also relates to my personal experience
        because it was visiting Italy many years ago that I first became
        interested in art and design because of the beautiful buildings,
        churches and works of art that I came across.
      </p>

      <h3 className={title3}>Design strategy</h3>

      <p className={parag}>
        A spirali is made up of several tails and each tail is made up of a
        basic element, which I called slice. A tail may have from one to
        thousands slices. It is mainly the variation in the number of slices and
        how they are colored that creates the different tail styles in each
        spirali.
      </p>
      <div className={imgContainer}>
        <figure>
          <StaticImage
            alt="Anatomy of a spirali"
            src="../images/spirali_anatomy.png"
          />
          <figcaption className={imgCaption}>
            Tails and slices in a spirali
          </figcaption>
        </figure>
      </div>

      <p className={parag}>
        The slices start at the center of the spiral and in each iteration they
        grow a certain length or split in two depending on the parameters used
        in the algorithm, which also determine the outline and fill of each
        slice when it is rendered.
      </p>
      <Video
        videoSrcURL="https://player.vimeo.com/video/749205564?h=db8bd7e9d0&autoplay=1&loop=1&title=0&byline=0&portrait=0"
        videoTitle="Spirali video"
        width="500"
        height="500"
      />

      <h3 className={title3}>Features</h3>

      <p className={parag}>The main features that a spirali can have are:</p>
      <ul>
        <li className={listItemExt}>
          Styles
          <ul>
            <li className={listItem}>
              Refers to the drawing patterns of each tail, that means, the
              shape, fill and stroke of the slices that form the tail. There are
              about 16 possible styles from which the algorithm will choose
              about 3 for each spirali.
            </li>
          </ul>
        </li>
        <li className={listItemExt}>
          Zoom out
          <ul>
            <li className={listItem}>
              It is related to how much larger the flow field area is in
              comparison to the image area. There are two possibilities: 50% and
              100%.
            </li>
          </ul>
        </li>
        <li className={listItemExt}>
          Curvature
          <ul>
            <li className={listItem}>
              An arbitrary measure of the rate the tails of a spirali are
              turning. A spirali can have high or low curvature.
            </li>
          </ul>
        </li>
        <li className={listItemExt}>
          Rotation
          <ul>
            <li className={listItem}>
              The rotation direction: clockwise or counterclockwise.
            </li>
          </ul>
        </li>
        <li className={listItemExt}>
          Tails
          <ul>
            <li className={listItem}>The number of tails in a spirali.</li>
          </ul>
        </li>
        <li className={listItemExt}>
          Palette
          <ul>
            <li className={listItem}>
              The palette's name. There are about 14 possible palettes.
            </li>
          </ul>
        </li>
      </ul>
      <div className={imgContainer2}>
        <figure>
          <StaticImage
            alt="Spirali with zoom out of 50%"
            src="../images/spirali_zoom_50.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>
            Spirali with zoom out of 50% and 12 tails
          </figcaption>
        </figure>
        <figure>
          <StaticImage
            alt="Spirali with zoom out of 100%"
            src="../images/spirali_zoom_100.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>
            Spirali with zoom out of 100% and 24 tails
          </figcaption>
        </figure>
      </div>
      <div className={imgContainer2}>
        <figure>
          <StaticImage
            alt="Spirali with high curvature"
            src="../images/spirali_high_curvature.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>High curvature spirali</figcaption>
        </figure>
        <figure>
          <StaticImage
            alt="Spirali with low curvature"
            src="../images/spirali_low_curvature.png"
            className={mediumImg}
          />
          <figcaption className={imgCaption}>Low curvature spirali</figcaption>
        </figure>
      </div>
      <h3 className={title3} id="faq">
        FAQ
      </h3>

      <p className={paragBold}>
        1) Why is the use of Chrome browser strongly recommended for this
        project?
      </p>
      <p className={parag}>
        When a token (e.g. an item of a collection) is minted, fxhash generates
        a preview image which is attached to the token as an alternative way of
        viewing it. This preview image is fixed and will be the same for all
        users, regardless of the browser they use.
      </p>
      <p className={parag}>
        However, when there is a request to dynamically generate an image of a
        token (for example, to obtain a high resolution version of the image)
        the generated image may differ from preview if any browser other than
        Chrome is used. In fxhash, this request happens, for example, when
        clicking on the "reload" or "open" links on a item page. If Firefox or
        Safari browser is used, the generated image will normally be different
        from preview.
      </p>
      <p className={`${paragBold} ${faqSpacer}`}>
        2) Was this project inspired by Tyler Hobbs' Fidenza collection?
      </p>
      <p className={parag}>
        You're probably expecting a yes, but the reality is a little more
        complicated than that. First of all I think it's important to say that I
        got to know the Tyler's work before Fidenza and I learned a lot from his
        essays. One of the things that struck me the most was a sentence in his{" "}
        <a
          href="https://tylerxhobbs.com/essays/2020/flow-fields"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          flow fields essay
        </a>{" "}
        that says the following: "It's entirely possible that I've used them
        (flow fields) in more programs than any other person alive." This
        perseverance, this pursuit to look at the same technique from a
        different perspective and always get something new out of it that was
        Spirali's true inspiration.
      </p>
      <p className={parag}>
        Certainly, Fidenza influenced me, but any similarity to it was
        unintentional. In fact, these similarities were more feared than sought.
        This project was only launched because I believe it has its own
        identity.
      </p>
      <p className={`${paragBold} ${faqSpacer}`}>
        3) What do you think about the association of this project with Fidenza
        to promote it?
      </p>
      <p className={parag}>
        I think it's a bad idea and I wouldn't recommend anyone to do it. This
        is a different project, we have to let it has its own life.
      </p>
      <p className={`${paragBold} ${faqSpacer}`}>
        4) What will be your next project?
      </p>
      <p className={parag}>
        I don't know yet, but I have some ideas. Anyway, this will probably take
        some months.
      </p>
      <p className={`${paragBold} ${faqSpacer}`}></p>
    </Layout>
  );
};

export default SpiraliPage;
